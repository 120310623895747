.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 25px ;
  min-width: 800px;

  font-family: 'DroidSans';
}


.header {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 50px;
}


.dateControls {
  display: flex;
  justify-content: center;
}

.tableContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: fit-content;

}

.title{
  color: #FF995B;
  flex: 1 1 auto;
  text-align: center;

  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
}

.buttons {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
}


.buttons > button {
  margin: 8px
}

.dateControls > div {
  margin: 30px;
}


.errorMessage {
  width: 357px;
  height: 56px;

  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #D33604;
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: white;
}

.errorMessage > * {
  margin: 5px ;
}