.container {
  display: flex;
  align-items: flex-start;
  justify-self: center;
}

.coreInput {
  display: none;
}

.title {
  margin: 0 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #7c8899;
}
