.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left:0;

  display: flex;
  align-items: center;
  justify-content: center;

}

.modal{
  width: 770px;
  height: 348px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 4px;
  background: #FFFFFF;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.title{
  height: 82px;

  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 4px solid;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.title > * {
  margin: 5px

}

.failTitle {
  border-color: red;
  color: red;
}

.successTitle {
  border-color: #66BB6A;
  color: #66BB6A;
}

.message {
  flex: 1 0 auto;
  padding: 0 150px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 18px;
  line-height: 156%;

  text-align: center;
  color: #333333;
}

.button{
  flex: none;

  padding: 20px 0 60px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}

