.tableContainer{
  flex: 1 1 auto;
  display: flex;
  flex-direction: center;
  align-items: center;

  min-width: fit-content;

}

.tableContainer table{
  border-spacing: 0;
}

.tableContainer tbody tr {
  box-shadow: 0px 1px 0px rgba(155, 155, 153, 0.25);
  background: rgba(231, 231, 231, 0.5);
}

.tableContainer th {
  padding: 0px 8px;

  font-weight: 700;
font-size: 12px;
line-height: 14px;

color: #333333;
height: 56px;
}

.tableContainer td {
  padding: 0px 8px;

  font-weight: 400;
font-size: 12px;
line-height: 14px;

color: #9B9B99;
height: 56px;

}

